import {
  useMutation,
  useQueryClient,
  type UseQueryOptions,
} from "@tanstack/react-query";

import {
  assignUserToLocation,
  createScreenerLocation,
  getAssignedScreenerLocations,
  getScreenerLocationsByScreener,
} from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type {
  AllLocationsResponse,
  ScreenerLocationDraft,
  ScreenerLocationsResponse,
} from "@/types/screeners";
import type { Nullable } from "@/types/utils";

const SCREENER_LOCATIONS_QUERY_KEY_BASE = "screener_location";
export const SCREENER_LOCATION_QUERY_KEYS = {
  base: [SCREENER_LOCATIONS_QUERY_KEY_BASE] as const,
  screener: (screenerId: string) => [
    ...SCREENER_LOCATION_QUERY_KEYS.base,
    screenerId,
  ],
  assigned: () => [...SCREENER_LOCATION_QUERY_KEYS.base, "assigned"],
};

export const useScreenerLocationsByScreenerQuery = (
  screenerId: string,
  config: Partial<UseQueryOptions<AllLocationsResponse>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_LOCATION_QUERY_KEYS.screener(screenerId),
    queryFn: () => getScreenerLocationsByScreener(screenerId),
    ...config,
  });
};

export const useAssignedScreenerLocationsQuery = (
  config: Partial<UseQueryOptions<ScreenerLocationsResponse>> = {},
) => {
  return useAuthedQuery({
    queryFn: getAssignedScreenerLocations,
    queryKey: SCREENER_LOCATION_QUERY_KEYS.assigned(),
    ...config,
  });
};

export const useAssignUserToLocationMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      locationId,
      userId,
    }: {
      locationId: string;
      userId: Nullable<string>;
    }) => assignUserToLocation(locationId, userId),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENER_LOCATION_QUERY_KEYS.base });
    },
  });
};

export const useCreateScreenerLocationMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({
      newLocation,
      screenerId,
    }: {
      newLocation: ScreenerLocationDraft;
      screenerId: string;
    }) => createScreenerLocation(screenerId, newLocation),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: SCREENER_LOCATION_QUERY_KEYS.base });
    },
  });
};
