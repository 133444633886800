import { faHighlighter } from "@fortawesome/pro-solid-svg-icons/faHighlighter";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Menu, Text } from "@mantine/core";

import { getCodeForHighlightColor, HIGHLIGHT_OPTIONS } from "@/utils/ui";

import { type Nullable } from "@/types/utils";

interface HighlightMenuProps {
  activeIndicatorColor?: string;
  onChange(value: Nullable<string>): void;
  withLabel?: boolean;
}

export const HighlightMenu = ({
  activeIndicatorColor,
  onChange,
  withLabel = false,
}: HighlightMenuProps) => {
  return (
    <Menu>
      <Menu.Target>
        <div className="flex items-center gap-2 cursor-pointer">
          {withLabel ? (
            <Button
              color="#784DD6"
              px={0}
              rightSection={
                <Flex>
                  <FontAwesomeIcon icon={faHighlighter} />
                  <FontAwesomeIcon icon={faCaretDown} />
                </Flex>
              }
              styles={{ inner: { justifyContent: "flex-start" } }}
              variant="transparent"
            >
              <Text fw={600} size="xs">
                HIGHLIGHT
              </Text>
            </Button>
          ) : (
            <ActionIcon
              variant="transparent"
              color="#784DD6"
              className="flex items-center"
            >
              <FontAwesomeIcon icon={faHighlighter} />
            </ActionIcon>
          )}
          {activeIndicatorColor && (
            <div
              className="w-4 h-4 rounded-full"
              style={{
                backgroundColor: activeIndicatorColor,
              }}
            />
          )}
        </div>
      </Menu.Target>
      <Menu.Dropdown className="z-50">
        {HIGHLIGHT_OPTIONS.map(({ label, value }) => (
          <Menu.Item
            key={value}
            onClick={(e) => {
              e.stopPropagation();

              onChange(value);
            }}
          >
            <div className="flex gap-2 items-center">
              <div
                className="w-4 h-4 rounded-full"
                style={{
                  backgroundColor: getCodeForHighlightColor(value),
                }}
              ></div>
              <span>{label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
