import { Divider, Select } from "@mantine/core";

import { type Screening } from "@/types/screeners";
import { type Nullable } from "@/types/utils";
import { getJobNameWithDisplayName } from "@/utils/ui";

const ScreeningMenu = ({
  activeSessionId,
  screenings,
  onChange,
}: {
  activeSessionId: Nullable<string>;
  screenings: Screening[];
  onChange: (value: string) => void;
}) => {
  const options = screenings.map((screening) => {
    const displayDate = new Date(screening.createdAt).toLocaleDateString(
      undefined,
      {
        day: "numeric",
        month: "short",
        year: "numeric",
      },
    );
    return {
      value: screening.sessionId,
      label: `${getJobNameWithDisplayName(
        screening.jobName,
        screening.screenerDisplayName,
      )} | ${screening.locationName} | ${displayDate}`,
    };
  });

  if (!options.length || options.length === 1) {
    return null;
  }

  return (
    <>
      <Divider my="xs" />
      <Select
        allowDeselect={false}
        comboboxProps={{ withinPortal: false }}
        data={options}
        label="Screeners"
        onChange={(value) => onChange(value as string)}
        withCheckIcon={false}
        value={activeSessionId}
      />
    </>
  );
};

export default ScreeningMenu;
