"use client";

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { Button, Menu, Text } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { type MouseEvent } from "react";

import { useUserContext } from "@/context/user.context";
import { useCallback } from "react";
import { useRouter } from "next/navigation";

const LOGOUT_PATH = "/auth/login?logout=true";

const AccountMenu = ({
  setCsvModalOpened,
}: {
  setCsvModalOpened?: (opened: boolean) => void;
}) => {
  const { logOut, user } = useUserContext();
  const router = useRouter();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      logOut();

      router.push(LOGOUT_PATH);
    },
    [logOut, router],
  );

  return (
    <Menu position="bottom-end" withinPortal={false}>
      <Menu.Target>
        <Button
          fullWidth
          variant="transparent"
          color="#784DD6"
          className="tracking-wider uppercase"
          size="xs"
          justify="end"
          rightSection={
            <FontAwesomeIcon icon={faChevronDown} className="indent-0" />
          }
        >
          Account
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{user?.email}</Menu.Label>
        {user?.internal && (
          <Menu.Item onClick={() => setCsvModalOpened?.(true)}>
            <Text className="font-normal" size="xs">
              Upload CSV
            </Text>
          </Menu.Item>
        )}
        <Menu.Item>
          <Link className="block" href={LOGOUT_PATH} onClick={handleClick}>
            Logout
          </Link>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AccountMenu;
