import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Image,
  Loader,
  Tabs,
  Text,
} from "@mantine/core";
import { usePrevious } from "@mantine/hooks";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import AccountMenu from "../accountMenu";
import ScreeningMenu from "./screeningMenu";
import TabContent from "./tabContent";
import { downloadPdf, viewPdf } from "@/app/api/screeners";
import { HighlightMenu } from "@/components/highlight-menu";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import {
  useCandidateScreeningsQuery,
  useHighlightScreenerSessionQuery,
  useMarkSessionViewedQuery,
} from "@/hooks/queries/screenings.queries";
import type { Nullable } from "@/types/utils";

export const CANDIDATE_DRAWER_TEST_IDS = {
  headerActionsContainer: "header-actions-container",
  mainContent: "main-content",
};

export interface CandidateDrawerProps {
  email?: string;
  navigationButtons?: ReactNode;
  onClose: () => void;
  opened: boolean;
  sessionId?: string;
  short?: boolean;
}

export const CandidateDrawer = ({
  email,
  navigationButtons,
  onClose,
  opened,
  sessionId,
  short = false,
}: CandidateDrawerProps) => {
  const track = useTrackAnalytics();
  const { mutate: mutateHighlight } = useHighlightScreenerSessionQuery();
  const { mutate: mutateSessionViewed } = useMarkSessionViewedQuery();
  const { data } = useCandidateScreeningsQuery(email, sessionId);
  const { screenings } = useMemo(
    () => ({
      screenings: data?.screenerSessions || [],
    }),
    [data],
  );
  const [activeTab, setActiveTab] = useState<string | null>(
    screenings[0]?.sessionId,
  );
  const activeScreening = useMemo(
    () => screenings?.find((s) => s.sessionId === activeTab),
    [activeTab, screenings],
  );
  const activeHighlightColor = useMemo(
    () => screenings?.find((s) => s.sessionId === activeTab)?.highlightColor,
    [activeTab, screenings],
  );
  const previousSessionId = usePrevious(sessionId);
  const previousActiveScreening = usePrevious(activeScreening);

  const onDownloadPdfClick = useCallback(() => {
    if (activeScreening) {
      track("Download PDF Clicked", {
        screener_session_id: activeScreening.sessionId,
      });

      downloadPdf(activeScreening?.pdfLocation || "");
    }
  }, [activeScreening, track]);

  const onViewPdfClick = useCallback(() => {
    if (activeScreening) {
      track("View PDF Clicked", {
        screener_session_id: activeScreening.sessionId,
      });

      viewPdf(activeScreening?.pdfLocation || "");
    }
  }, [activeScreening, track]);

  const handleHighlight = async (value: Nullable<string>) => {
    if (activeScreening) {
      track("Candidate Highlighted", {
        highlight_color: value || "None",
        screener_session_id: activeScreening.sessionId,
      });

      mutateHighlight({ id: activeScreening.sessionId, value });
    }
  };

  useEffect(() => {
    const currentSessionIsUnviewed =
      activeScreening &&
      !activeScreening.viewedAt &&
      activeScreening.sessionId !== previousActiveScreening?.sessionId;
    const currentSessionIsIncomplete = !!sessionId && !email;

    // This will fire on every render for sessions without an email. As there
    // is nothing the user can actually do or interact with when there is no
    // email this shouldn't be a problem. This is due to needing to rely on
    // email for the query, without a provided email we have no way to know
    // whether the session has been viewed or not.
    if (currentSessionIsUnviewed || currentSessionIsIncomplete) {
      // Safe to cast, TypeScript is just losing the thread. As long as one of
      // the above is true the following will always result in a string.
      mutateSessionViewed((activeScreening?.sessionId ?? sessionId) as string);
    }
  }, [
    activeScreening,
    activeTab,
    email,
    mutateSessionViewed,
    previousActiveScreening,
    sessionId,
  ]);

  useEffect(() => {
    const noScreenings = screenings.length < 1;
    const newSessionId = sessionId !== previousSessionId;
    const activeScreening = screenings.find(
      (screening) => screening.sessionId === sessionId,
    );

    // If there are no screenings or we can't find our session ID in the list of
    // screenings we are probably in a loading state. Set to null and wait for
    // the right data.
    if (noScreenings || !activeScreening) {
      return setActiveTab(null);
    }

    // Initially setting active tab if the screenings query was not yet memoized
    if (!activeTab) {
      return setActiveTab(screenings[0].sessionId);
    }

    // A user navigated to a new session without closing the drawer. Try to find
    // the specific session they navigated to (for cases where a user has more
    // than one screening) and fall back to the first screening.
    if (newSessionId) {
      return setActiveTab(activeScreening.sessionId);
    }
  }, [activeTab, previousSessionId, screenings, sessionId]);

  return (
    <Drawer
      onClose={onClose}
      opened={opened}
      overlayProps={{ backgroundOpacity: 0.1, blur: 0 }}
      position="right"
      shadow="xl"
      size="lg"
      styles={{ body: { padding: 0 }, inner: short ? { top: 112 } : {} }}
      withCloseButton={false}
      withOverlay={!short}
    >
      <div className="p-4 pb-0">
        <div className="w-full flex justify-between items-center">
          <Image src="/assets/logo.svg" alt="Logo" h={20} />
          <ActionIcon
            variant="transparent"
            onClick={onClose}
            aria-label="close"
          >
            <Image src="/assets/circle-x.svg" alt="Logo" h={20} />
          </ActionIcon>
        </div>
        <Divider my="xs" />
        {!email && (
          <div className="w-full h-dvh flex justify-center items-center">
            <Text>Incomplete Screening Session</Text>
          </div>
        )}
        {email && screenings.length === 0 && (
          <div className="w-full h-dvh flex justify-center items-center">
            <Loader color="#88D8F1" role="progressbar" />
          </div>
        )}
        {screenings.length > 0 && (
          <>
            <div
              className="w-full p-2 flex justify-between items-center gap-4"
              data-testid={CANDIDATE_DRAWER_TEST_IDS.headerActionsContainer}
            >
              <div className="w-full flex gap-4 justify-between items-center">
                <div className="w-full flex gap-4 items-center">
                  <Button
                    size="xs"
                    color="#664ccb"
                    radius="md"
                    rightSection={<FontAwesomeIcon icon={faDownload} />}
                    onClick={onDownloadPdfClick}
                  >
                    Download PDF
                  </Button>
                  <Button
                    variant="outline"
                    size="xs"
                    color="#664ccb"
                    radius="md"
                    rightSection={<FontAwesomeIcon icon={faDownload} />}
                    onClick={onViewPdfClick}
                  >
                    View PDF
                  </Button>
                  <HighlightMenu
                    activeIndicatorColor={activeHighlightColor}
                    onChange={handleHighlight}
                  />
                </div>
                <AccountMenu />
              </div>
            </div>
            <div
              className="w-full"
              data-testid={CANDIDATE_DRAWER_TEST_IDS.mainContent}
            >
              <ScreeningMenu
                activeSessionId={activeTab}
                screenings={screenings}
                onChange={(value: string | null) => setActiveTab(value)}
              />
              <Tabs
                value={activeTab}
                variant="pills"
                color="#ebe7f8"
                onChange={(value: string | null) => setActiveTab(value)}
              >
                <Divider my="xs" />
                {activeTab && (
                  <Tabs.Panel value={activeTab}>
                    <TabContent
                      data={screenings.find((s) => s.sessionId === activeTab)}
                    />
                  </Tabs.Panel>
                )}
              </Tabs>
            </div>
          </>
        )}
      </div>
      {navigationButtons}
    </Drawer>
  );
};
