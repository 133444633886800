import axios from "axios";
import { jwtDecode } from "jwt-decode";

import type { ServerUser, User, UserListUser } from "@/types/user";

export const TOKEN_LOCALSTORAGE_KEY = "token";
export const REFRESH_TOKEN_LOCALSTORAGE_KEY = "refreshToken";

interface DecodedToken {
  exp: number;
  iat: number;
  sub: string;
}

interface TokenInfo {
  expired: boolean;
  decoded: DecodedToken;
  token: string;
}

// This needs to live outside the class for overloading
function decodeAndCheckTokenExpiration(token: string): TokenInfo;
function decodeAndCheckTokenExpiration(token: null): null;
function decodeAndCheckTokenExpiration(token: string | null): TokenInfo | null {
  if (!token) {
    return null;
  }

  const decoded = jwtDecode<DecodedToken>(token);
  const now = Date.now() / 1000;

  return {
    expired: decoded.exp < now,
    decoded,
    token,
  };
}

class UserService {
  private readonly BASE_URL = `${process.env.NEXT_PUBLIC_API_URL}`;

  private refreshTokenTimeoutID?: NodeJS.Timeout;

  private onRefreshFailure?: () => void;

  axiosWithUserAuth = axios.create({
    baseURL: this.BASE_URL,
  });

  private setAxiosInstance(token?: string) {
    this.axiosWithUserAuth = axios.create({
      baseURL: this.BASE_URL,
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    });
  }

  private getAndDecodeTokens() {
    const token = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

    if (!token || !refreshToken) {
      return {};
    }

    const decodedSessionToken = decodeAndCheckTokenExpiration(token);
    const decodedRefreshToken = decodeAndCheckTokenExpiration(refreshToken);

    return {
      sessionToken: decodedSessionToken,
      refreshToken: decodedRefreshToken,
    };
  }

  private formatUser(user: ServerUser): User {
    const formatDateOrNull = (date: string | null) => {
      if (date) {
        return new Date(date);
      }

      return null;
    };

    return {
      ...user,
      deletedAt: formatDateOrNull(user.deletedAt),
      createdAt: formatDateOrNull(user.createdAt),
      updatedAt: formatDateOrNull(user.updatedAt),
      company: {
        ...user.company,
        createdAt: formatDateOrNull(user.company.createdAt),
        updatedAt: formatDateOrNull(user.company.updatedAt),
      },
    };
  }

  initialize(onRefreshFailure: () => void) {
    this.onRefreshFailure = onRefreshFailure;

    const { sessionToken, refreshToken } = this.getAndDecodeTokens();

    if (!sessionToken || !refreshToken) {
      return Promise.resolve(null);
    }

    // Session token is still valid, use it
    if (!sessionToken.expired) {
      this.setAxiosInstance(sessionToken.token);
      this.startRefreshTokenTimer(sessionToken, refreshToken);

      return this.getUser(sessionToken.decoded.sub);
    }

    // Session token has expired, but refresh token is still valid. Refresh session token
    if (!refreshToken.expired) {
      return this.getRefreshedToken(
        sessionToken.decoded.sub,
        refreshToken.token,
      ).then(({ accessToken }) => this.getUser(accessToken.decoded.sub));
    }

    // Both tokens have expired, clean them up
    localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

    return Promise.resolve(null);
  }

  startRefreshTokenTimer = (
    sessionToken: TokenInfo,
    refreshToken: TokenInfo,
  ) => {
    const ONE_MINUTE = 60 * 1000;

    const sessionTimeToExpiry = sessionToken.decoded.exp * 1000 - Date.now();
    const refreshTimeToExpiry = refreshToken.decoded.exp * 1000 - Date.now();

    if (
      sessionToken.expired ||
      sessionTimeToExpiry <= ONE_MINUTE ||
      refreshTimeToExpiry <= ONE_MINUTE
    ) {
      // Get new tokens and come back to this.
      this.getRefreshedToken(sessionToken.decoded.sub, refreshToken.token);
    } else {
      if (this.refreshTokenTimeoutID) {
        clearTimeout(this.refreshTokenTimeoutID);
      }

      this.refreshTokenTimeoutID = setTimeout(
        () => {
          this.getRefreshedToken(sessionToken.decoded.sub, refreshToken.token);
        },
        Math.min(sessionTimeToExpiry, refreshTimeToExpiry) - ONE_MINUTE,
      );
    }
  };

  getUser(userId: string) {
    return this.axiosWithUserAuth
      .get<ServerUser>(`/user/${userId}`)
      .then(({ data }) => this.formatUser(data));
  }

  getUsers() {
    return this.axiosWithUserAuth
      .get<{ users: UserListUser[] }>("/company/users")
      .then(({ data }) => data.users);
  }

  updateUserName(fullName: string) {
    return this.axiosWithUserAuth.patch("/user/full_name", { fullName });
  }

  getRefreshedToken = (userId: string, refreshToken: string) => {
    return axios
      .get<{
        accessToken: string;
        refreshToken: string;
      }>(`${this.BASE_URL}/auth/refresh-tokens?userId=${userId}`, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      })
      .then(({ data: { accessToken, refreshToken } }) => {
        localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, accessToken);
        localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, refreshToken);

        this.setAxiosInstance(accessToken);
        this.startRefreshTokenTimer(
          decodeAndCheckTokenExpiration(accessToken),
          decodeAndCheckTokenExpiration(refreshToken),
        );

        return {
          accessToken: decodeAndCheckTokenExpiration(accessToken),
          refreshToken: decodeAndCheckTokenExpiration(refreshToken),
        };
      })
      .catch((error) => {
        const code = error?.response?.data?.statusCode;

        if (code === 403 || code === 401) {
          this.onRefreshFailure?.();
        }

        throw error;
      });
  };

  login(email: string, password: string, mfaToken?: string, phone?: string) {
    return this.axiosWithUserAuth
      .post<{ accessToken: string; refreshToken: string; user: ServerUser }>(
        "/auth/login",
        {
          email,
          mfaToken,
          password,
          phone,
        },
      )
      .then(({ data: { accessToken, refreshToken, user } }) => {
        localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, accessToken);
        localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, refreshToken);

        this.startRefreshTokenTimer(
          decodeAndCheckTokenExpiration(accessToken),
          decodeAndCheckTokenExpiration(refreshToken),
        );
        this.setAxiosInstance(accessToken);

        return this.formatUser(user);
      });
  }

  // For first-time MFA setup we have to pass phone number down. Once MFA has
  // been configured for the user it can be safely omitted.
  sendSMSCode(email: string, phone?: string) {
    return this.axiosWithUserAuth.post("/auth/mfa", {
      email,
      method: "sms",
      phone,
    });
  }

  updatePhoneNumber(phone: string, mfaToken: string) {
    return this.axiosWithUserAuth.post("/auth/mfa/update", {
      method: "sms",
      mfaToken,
      value: phone,
    });
  }

  forgotPassword(email: string) {
    return this.axiosWithUserAuth
      .post("/auth/forgot-password", {
        email,
      })
      .then(({ data }) => data);
  }

  resetPassword(
    email: string,
    newPassword: string,
    passwordResetToken?: string,
    fullName?: string,
  ) {
    return this.axiosWithUserAuth
      .post("/auth/reset-password", {
        email,
        fullName,
        newPassword,
        passwordResetToken,
      })
      .then(({ data }) => data);
  }

  logOut() {
    localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

    if (this.refreshTokenTimeoutID) {
      clearTimeout(this.refreshTokenTimeoutID);
    }

    this.setAxiosInstance();
  }
}

export const userService = new UserService();
