import { Badge, Skeleton, Text, Tooltip } from "@mantine/core";
import { useMemo } from "react";

import competencyBlocks from "./competencyBlocks";
import { QuestionTranscript } from "./question-transcript";
import { getBadgeColor } from "@/utils/ui";
import { QuestionTypes, type Screening } from "@/types/screeners";

const overallFitColor = (overallFit: string) => {
  switch (overallFit) {
    case "High":
      return ["#b9e7df", "#2e6e5f"];
    case "Medium":
      return ["#faedc1", "#F45A5A"];
    case "Low":
      return ["#edc4c0", "#a63a2f"];
    default:
      return ["#e2e2e2", "#666666"];
  }
};

const isVisibleOnPdfElements = (data: Screening) => {
  const items = data.isVisibleOnPdf;

  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }
  return (
    <>
      {items.map((item: { questionLabel: string; message: string }) => (
        <div
          className="flex gap-2 justify-evenly items-center"
          key={item.questionLabel}
        >
          <Text size="md" fw={500}>
            {item.questionLabel.charAt(0).toUpperCase() +
              item.questionLabel.slice(1).toLowerCase()}
            :
          </Text>
          <Badge color="lightgray" variant="filled">
            <Tooltip label={item.message}>
              <Text size="xs" c="#666666" fw={800} truncate>
                {item.message.slice(0, 20)}
              </Text>
            </Tooltip>
          </Badge>
        </div>
      ))}
    </>
  );
};

const TabContent = ({ data }: { data?: Screening }) => {
  const qualifyingPassRateIsNA = useMemo(
    () => data && !data.qualifyingPassRate && data.qualifyingPassRate !== 0,
    [data],
  );
  const qualifyingPassRateBadgeColor = useMemo(
    () =>
      data
        ? getBadgeColor(
            qualifyingPassRateIsNA ? "N/A" : data.qualifyingPassRate.toString(),
            "qualifyingPassRate",
          )
        : { background: "", text: "" },
    [data, qualifyingPassRateIsNA],
  );

  if (!data) {
    return (
      <div className="flex flex-col gap-4">
        <Skeleton height={200} width="100%" />
        <Skeleton height={200} width="100%" />
        <Skeleton height={200} width="100%" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 jusitfy-start items-center p-4">
      <div className="w-full flex justify-start items-center text-start">
        <Text size="xl" fw={600}>
          {data.candidateName}
        </Text>
      </div>
      <div className="w-full border border-gray-200 p-4 rounded-lg flex flex-col justify-between items-start gap-4">
        <Text size="xl" fw={600}>
          Screener Details
        </Text>
        <Text fw={500}>{`Started ${new Date(
          data.createdAt as string,
        ).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}`}</Text>
        <Text fw={500}>
          {data.time} | {data.words}
        </Text>
        <div className="flex gap-2 items-center">
          <Text size="md">Status: </Text>
          <Badge size="md" color="#b9e7df" variant="filled">
            <Text size="xs" c="#2e6e5f" fw={800}>
              {data.sessionStatus.charAt(0).toUpperCase() +
                data.sessionStatus.slice(1)}
            </Text>
          </Badge>
        </div>
      </div>
      <div className="w-full border border-gray-200 p-4 rounded-lg flex flex-col justify-between items-start gap-4">
        <Text size="xl" fw={600}>
          Candidate Information
        </Text>
        <Text size="lg" fw={500}>
          {data.candidateName}
        </Text>
        <Text size="md" fw={300}>
          {data.candidateEmail} | {data.candidatePhone}
        </Text>
        {data.language && (
          <Text size="md" fw={300}>
            Language: {data.language}
          </Text>
        )}
        {isVisibleOnPdfElements(data)}
        <Text size="md" fw={300}>
          Location: {data.locationName}
        </Text>
        <div className="flex gap-2 items-center">
          <Text size="md">Knockout Score: </Text>
          <Badge
            size="md"
            color={qualifyingPassRateBadgeColor.background}
            variant="filled"
          >
            <Text size="xs" c={qualifyingPassRateBadgeColor.text} fw={800}>
              {qualifyingPassRateIsNA ? "N/A" : data.qualifyingPassRate + "%"}
            </Text>
          </Badge>
        </div>
      </div>
      {data.overallFit && (
        <div
          className={`w-full flex flex-col gap-2 border border-gray-200 p-4 rounded-lg bg-[${
            overallFitColor(data.overallFit)[0]
          }]`}
        >
          <div className="flex justify-between items-center">
            <p className="text-lg font-semibold">Overall Fit</p>
            <Badge
              color={overallFitColor(data.overallFit)[0]}
              variant="filled"
              size="md"
            >
              <Text size="sm" c={overallFitColor(data.overallFit)[1]} fw={700}>
                {data.overallFit}
              </Text>
            </Badge>
          </div>
          <p>
            Overall fit combines all the competencies below. Competencies are
            set by company leaders to predict retention, performance, and fit.
            We welcome feedback on competencies and encourage you to dive into
            the quotes that speak to each one.
          </p>
        </div>
      )}
      {data.competencies && (
        <div className="w-full flex flex-col gap-2 justify-between items-center">
          {competencyBlocks(data.competencies)}
        </div>
      )}

      {data.knockoutQuestions && data.knockoutQuestions.length > 0 && (
        <QuestionTranscript
          questionType={QuestionTypes.Knockout}
          questions={data.knockoutQuestions}
        />
      )}
      {data.behavioralQuestions && data.behavioralQuestions.length > 0 && (
        <QuestionTranscript
          questionType={QuestionTypes.Behavioral}
          questions={data.behavioralQuestions}
        />
      )}
    </div>
  );
};

export default TabContent;
