import { SessionStatus } from "@/types/screeners";
import { type Colors } from "@/utils/ui";

export enum SearchResultType {
  Screener = "screener",
  Location = "location",
  Session = "session",
}

interface BaseUniversalSearchResult {
  internalName: string;
  lastSubmissionDate?: string;
  location: string;
  recordId: string;
  resultOrder: number;
  screenerName: string;
  sessionCount?: number;
  similarity: number;
  type: SearchResultType;
}

interface SessionUniversalSearchResult extends BaseUniversalSearchResult {
  candidateEmail?: string;
  candidateName?: string;
  candidatePhone?: string;
  highlightColor?: Colors;
  sessionCreatedAt: string;
  sessionStatus: SessionStatus;
  type: SearchResultType.Session;
}

interface LocationUniversalSearchResult extends BaseUniversalSearchResult {
  locationStatus: boolean;
  type: SearchResultType.Location;
}

interface ScreenerUniversalSearchResult extends BaseUniversalSearchResult {
  locationCount: number;
  screenerStatus: "live" | "mixed" | "paused";
  type: SearchResultType.Screener;
}

export type UniversalSearchResult =
  | SessionUniversalSearchResult
  | LocationUniversalSearchResult
  | ScreenerUniversalSearchResult;
