import { type UseQueryOptions } from "@tanstack/react-query";

import { getUniversalSearchResults } from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { UniversalSearchResult } from "@/types/universal-search";
import { useEffect, useState } from "react";

const UNIVERSAL_SEARCH_QUERY_KEYS = {
  base: ["UNIVERSAL_SEARCH"] as const,
  query: (query: string) =>
    [...UNIVERSAL_SEARCH_QUERY_KEYS.base, query] as const,
};

export const useUniversalSearchQuery = (
  query = "",
  config: Partial<UseQueryOptions<UniversalSearchResult[]>> = {},
) => {
  // We manage whether or not to use previous data on load to avoid problems
  // where a user has cleared the input and starts a new search. If we don't
  // manage the state here the user will see the previous set of results in the
  // dropdown until the new set loads.
  const [usePreviousData, setUsePreviousData] = useState(false);

  useEffect(() => {
    if (query === "") {
      setUsePreviousData(false);
    }
  }, [query]);

  return useAuthedQuery({
    placeholderData: (previousData) => (usePreviousData ? previousData : []),
    queryFn: () => {
      return getUniversalSearchResults(query).then((result) => {
        setUsePreviousData(true);

        return result;
      });
    },
    queryKey: UNIVERSAL_SEARCH_QUERY_KEYS.query(query),
    ...config,
  });
};
