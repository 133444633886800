import { faCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Flex, Text, useCombobox } from "@mantine/core";
import { useCallback } from "react";

import { DateRangeFilterOption } from "./date-range-filter-option";
import { ComboboxTargetButton } from "@/components/ui/combobox-target-button";
import { DateRangeOptions } from "@/types/date-range-filter";
import type { Nullable } from "@/types/utils";

export const DATE_RANGE_FILTER_TEST_IDS = {
  dateRangeFilterDropdown: "date-range-filter-dropdown",
};

export interface DateRangeFilterProps {
  dateRange: Nullable<DateRangeOptions>;
  setDateRange(dateRange: Nullable<DateRangeOptions>): void;
}

const relativeCalendarOptions = [
  {
    helperText: "Today from midnight to now",
    value: DateRangeOptions.Today,
  },
  {
    helperText: "The previous 24-hour day",
    value: DateRangeOptions.Yesterday,
  },
  {
    helperText: "The current calendar week",
    value: DateRangeOptions.ThisWeek,
  },
  {
    helperText: "The previous calendar week",
    value: DateRangeOptions.LastWeek,
  },
  {
    helperText: "The current calendar month",
    value: DateRangeOptions.ThisMonth,
  },
  {
    helperText: "The previous calendar month",
    value: DateRangeOptions.LastMonth,
  },
];

const relativeDaysOptions = [
  {
    helperText: "The previous 3 days before today",
    value: DateRangeOptions.LastThreeDays,
  },
  {
    helperText: "The previous 7 days before today",
    value: DateRangeOptions.LastSevenDays,
  },
  {
    helperText: "The previous 14 days before today",
    value: DateRangeOptions.LastFourteenDays,
  },
  {
    helperText: "The previous 30 days before today",
    value: DateRangeOptions.LastThirtyDays,
  },
  {
    helperText: "Select a custom range",
    value: DateRangeOptions.CustomRange,
  },
];

export const DateRangeFilter = ({
  dateRange,
  setDateRange,
}: DateRangeFilterProps) => {
  const combobox = useCombobox();

  const onOptionClick = useCallback(
    (value: DateRangeOptions) => {
      combobox.toggleDropdown();
      setDateRange(value);
    },
    [combobox, setDateRange],
  );

  return (
    <Combobox
      dropdownPadding={18}
      position="bottom-start"
      store={combobox}
      transitionProps={{ duration: 300, transition: "pop-top-left" }}
      width={475}
    >
      <ComboboxTargetButton
        leftSection={<FontAwesomeIcon size="sm" icon={faCalendar} />}
        onClear={() => setDateRange(null)}
        showClearButton={dateRange !== null}
        store={combobox}
      >
        <Text c={dateRange ? "black" : "#999999"} size="sm">
          {dateRange ?? "Select range..."}
        </Text>
      </ComboboxTargetButton>

      <Combobox.Dropdown
        data-testid={DATE_RANGE_FILTER_TEST_IDS.dateRangeFilterDropdown}
        style={{ borderRadius: 6, boxShadow: "0px 0px 6px 0px #0000001A" }}
      >
        <Combobox.Options>
          <Flex>
            <Flex direction="column">
              {relativeCalendarOptions.map((option) => (
                <DateRangeFilterOption
                  key={option.value}
                  {...option}
                  onClick={onOptionClick}
                />
              ))}
            </Flex>
            <Flex direction="column">
              {relativeDaysOptions.map((option) => (
                <DateRangeFilterOption
                  key={option.value}
                  {...option}
                  onClick={onOptionClick}
                />
              ))}
            </Flex>
          </Flex>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
