import { userService } from "@/app/api/user";
import type {
  AllLocationsResponse,
  CsvUploadTranslationResponse,
  CandidateResponse,
  UserCandidateResponse,
  AllScreenersResponse,
  ScreenerLocationDraft,
  ScreenerLocationsResponse,
} from "@/types/screeners";
import type { UniversalSearchResult } from "@/types/universal-search";
import type { Nullable } from "@/types/utils";
import { addHighPercentileIndicators } from "@/utils/api";

export const getAllScreeners = async () => {
  try {
    const response =
      await userService.axiosWithUserAuth.get<AllScreenersResponse>(
        "/screener",
      );
    return response.data;
  } catch (error) {
    console.error("Error fetching screeners:", error);
    throw error;
  }
};

export const getScreenerLocationsByScreener = async (
  screenerId: string,
): Promise<AllLocationsResponse> => {
  try {
    const response =
      await userService.axiosWithUserAuth.get<AllLocationsResponse>(
        `/screener-location/cx/${screenerId}/`,
      );
    return response.data;
  } catch (error) {
    console.error("Error fetching screener location data:", error);
    throw error;
  }
};

export const getAssignedScreenerLocations =
  async (): Promise<ScreenerLocationsResponse> => {
    try {
      const response =
        await userService.axiosWithUserAuth.get<ScreenerLocationsResponse>(
          "/screener-location/assigned",
        );
      return response.data;
    } catch (error) {
      console.error("Error fetching assigned screener locations:", error);
      throw error;
    }
  };

// Get all screenings for a specific Job / Screener Location
export const getScreenerLocation = async (
  screenerLocationId: string,
): Promise<CandidateResponse> => {
  try {
    const response = await userService.axiosWithUserAuth.get<CandidateResponse>(
      `/screener-session/cx/screener-location/${screenerLocationId}`,
    );
    return addHighPercentileIndicators(response.data);
  } catch (error) {
    console.error("Error fetching screening data:", error);
    throw error;
  }
};

export const assignUserToLocation = async (
  locationId: string,
  userId: Nullable<string>,
) => {
  try {
    const response = await userService.axiosWithUserAuth.patch(
      `/screener-location/${locationId}/assign`,
      { userId },
    );

    return response.data;
  } catch (error) {
    console.error("Error assigning user to location:", error);
    throw error;
  }
};

export const createScreenerLocation = async (
  screenerId: string,
  newLocation: ScreenerLocationDraft,
) => {
  try {
    const response = await userService.axiosWithUserAuth.post(
      `/screener-location/cx/${screenerId}`,
      newLocation,
    );

    return response.data;
  } catch (error) {
    console.error("Error creating screener location:", error);
    throw error;
  }
};

// Get all screenings for a specific Candidate
export const getCandidateScreenings = async (
  email: string,
  sessionId?: string,
): Promise<CandidateResponse> => {
  try {
    const response =
      await userService.axiosWithUserAuth.get<UserCandidateResponse>(
        `/screener-session/cx/email/${email}${sessionId ? `/?screener-session-id=${sessionId}` : ""}`,
      );
    return {
      ...response.data,
      // The format of this response is slightly different from fetching screenings by location ID, massage them into
      // the same format.
      screenerSessions: response.data.screenerSessions.map(
        ({ competencyFields, isVisibleOnPdfFields, ...session }) => ({
          ...session,
          competencies: competencyFields,
          isVisibleOnPdf: isVisibleOnPdfFields,
        }),
      ),
    };
  } catch (error) {
    console.error("Error fetching candidate data:", error);
    throw error;
  }
};

export const highlightScreenerSession = async (
  id: string,
  value: Nullable<string>,
): Promise<CandidateResponse> => {
  try {
    const response =
      await userService.axiosWithUserAuth.patch<CandidateResponse>(
        `/screener-session/${id}`,
        {
          highlightColor: value,
        },
      );
    return response.data;
  } catch (error) {
    console.error("Error editing screening:", error);
    throw error;
  }
};

export const bulkHighlightScreenerSession = async (
  sessionIds: string[],
  highlightColor: Nullable<string>,
): Promise<CandidateResponse> => {
  try {
    const response =
      await userService.axiosWithUserAuth.patch<CandidateResponse>(
        `/screener-session/session-bulk-update`,
        {
          highlightColor,
          sessionIds,
        },
      );
    return response.data;
  } catch (error) {
    console.error("Error editing screening:", error);
    throw error;
  }
};

export const bulkSetSessionViewedStatus = async (
  sessionIds: string[],
  viewedAt: Nullable<string>,
) => {
  try {
    const response =
      await userService.axiosWithUserAuth.patch<CandidateResponse>(
        "/screener-session/session-bulk-update",
        { viewedAt, sessionIds },
      );
    return response.data;
  } catch (error) {
    console.error("Error editing screening:", error);
    throw error;
  }
};

export const getSessionCsv = async (
  screenerLocationId: string,
): Promise<string> => {
  try {
    const response = await userService.axiosWithUserAuth.get<string>(
      `/screener-session/cx/csv/${screenerLocationId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching screening csv:", error);
    throw error;
  }
};

export const downloadPdf = async (pdfId: string) => {
  const response = await userService.axiosWithUserAuth.get(
    `/superscreener-pdf/url/${pdfId}`,
  );

  // initiate browser download
  const { signedDownloadUrl } = response.data;
  const link = Object.assign(document.createElement("a"), {
    href: signedDownloadUrl,
    target: "_blank",
    download: "screener.pdf",
  });
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return response.data;
};

export const viewPdf = async (pdfId: string) => {
  const response = await userService.axiosWithUserAuth.get(
    `/superscreener-pdf/url/${pdfId}`,
  );
  const { signedUrl } = response.data;
  window.open(signedUrl, "_blank");
};

export const reprocessScreenerSession = async (ids: string) => {
  const response = await userService.axiosWithUserAuth.get(
    `/screener-session/reprocess/${ids}`,
  );
  return response.data;
};

export const markSessionViewed = async (sessionId: string) => {
  const response = await userService.axiosWithUserAuth.get(
    `/screener-session/viewed/${sessionId}`,
  );

  return response.data;
};

export const uploadCsv = async (file: File): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await userService.axiosWithUserAuth.post<string>(
      `/screener/csv/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading csv:", error);
    throw error;
  }
};

export const uploadTranslationCsv = async (
  file: File,
): Promise<CsvUploadTranslationResponse> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response =
      await userService.axiosWithUserAuth.post<CsvUploadTranslationResponse>(
        `/translation/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    return response.data;
  } catch (error) {
    console.error("Error uploading translation csv:", error);
    throw error;
  }
};

export const getUniversalSearchResults = async (query: string) => {
  try {
    const response = await userService.axiosWithUserAuth.get<
      UniversalSearchResult[]
    >(`screener/search?query=${encodeURIComponent(query)}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching Universal Search results:", error);
    throw error;
  }
};
