import { ComboboxTargetButton } from "@/components/ui/combobox-target-button";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Combobox, Text, useCombobox } from "@mantine/core";
import type { ColumnFiltersState } from "@tanstack/react-table";
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { twMerge } from "tailwind-merge";

import { useFilterAnalytics } from "@/hooks/analytics.hooks";
import { useUsersQuery } from "@/hooks/queries/user.queries";
import {
  type Filter,
  handleSimpleArrayFilterChange,
  useFilterState,
} from "@/hooks/use-filter-state.hook";
import type { UserListUser } from "@/types/user";
import type { Maybe } from "@/types/utils";

interface AssignedUserFilterProps {
  filters: ColumnFiltersState;
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
}

export const NOT_ASSIGNED = "Not assigned";
const FILTER_NAME = "assignedTo";

export const AssignedUserFilter = ({
  filters,
  setFilters,
}: AssignedUserFilterProps) => {
  const { data: users = [] } = useUsersQuery();
  const combobox = useCombobox();
  const trackFilter = useFilterAnalytics("Assigned to");
  const [filterValue, updateFilter] = useFilterState<Maybe<string[]>>(
    filters as Filter[],
    setFilters,
    FILTER_NAME,
  );

  const handleFilterChange = useMemo(
    () =>
      handleSimpleArrayFilterChange<string>(
        updateFilter,
        filterValue,
        trackFilter,
      ),
    [filterValue, trackFilter, updateFilter],
  );

  const selectedUserNames = useMemo(() => {
    if (users.length < 1) {
      return;
    }

    const usersById: { [id: string]: UserListUser } = users.reduce(
      (byId, user) => ({ ...byId, [user.id]: user }),
      {},
    );

    return filterValue
      ?.reduce((selected, userId) => {
        if (userId === NOT_ASSIGNED) {
          return [...selected, NOT_ASSIGNED];
        }

        const user = usersById[userId];

        // If the user no longer exists in the org, remove them from the filter
        if (!user) {
          handleFilterChange(userId);
          return selected;
        }

        return [...selected, user.fullName ?? user.email];
      }, [] as string[])
      .join(", ");
  }, [filterValue, handleFilterChange, users]);

  const renderOption = useCallback(
    (label: string, value: string, isLastOption = false) => {
      return (
        <Combobox.Option
          className={twMerge(["flex", !isLastOption && "border-b", "w-full"])}
          key={value}
          p={12}
          value={value}
        >
          <Checkbox
            checked={filterValue?.includes(value) || false}
            mr={8}
            readOnly
          />
          <Text size="sm">{label}</Text>
        </Combobox.Option>
      );
    },
    [filterValue],
  );

  return (
    <Combobox
      offset={6}
      onOptionSubmit={handleFilterChange}
      position="bottom-start"
      radius={6}
      store={combobox}
      transitionProps={{ duration: 300, transition: "pop-top-left" }}
      width="max-content"
    >
      <ComboboxTargetButton
        leftSection={<FontAwesomeIcon className="text-sm" icon={faUser} />}
        onClear={() =>
          setFilters((filters) =>
            filters.filter(({ id }) => id !== FILTER_NAME),
          )
        }
        showClearButton={!!filterValue?.length}
        store={combobox}
      >
        {users.length && filterValue?.length ? (
          <Text
            c="#333333"
            className={twMerge([
              "overflow-ellipsis",
              "overflow-hidden",
              "w-[120px]",
              "whitespace-nowrap",
            ])}
            size="sm"
            ta="left"
          >
            {selectedUserNames}
          </Text>
        ) : (
          "Select..."
        )}
      </ComboboxTargetButton>
      <Combobox.Dropdown className="overflow-y-auto" mah={330} p={0}>
        <Combobox.Options>
          {renderOption(NOT_ASSIGNED, NOT_ASSIGNED)}
          {users.map(({ email, fullName, id }, index) =>
            renderOption(fullName ?? email, id, index === users.length - 1),
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
