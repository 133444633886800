import { Badge } from "@mantine/core";
import type { Competencies } from "@/types/screeners";

const blockStyles = {
  High: "#3c917d",
  Medium: "#dabd4e",
  Low: "#ef764b",
};

const competencyBlocks = (competencies: Competencies) => {
  return (
    <div className="w-full flex flex-col gap-4">
      {Object.keys(competencies).map((competency, i) => {
        const { score, quote } = competencies[competency];

        return (
          <div
            key={i + competency}
            className={`flex flex-col gap-2 border border-gray-200 p-4 rounded-lg`}
          >
            <div className="flex justify-between items-center">
              <p className="text-lg font-semibold truncate">{competency}</p>
              <Badge
                color={blockStyles[score as keyof typeof blockStyles]}
                variant="outline"
                size="lg"
                className="min-w-fit px-2"
              >
                {score}
              </Badge>
            </div>
            <p className="text-md">{quote || "No quote available"}</p>
          </div>
        );
      })}
    </div>
  );
};

export default competencyBlocks;
