import type { UseQueryOptions } from "@tanstack/react-query";

import { userService } from "@/app/api/user";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import { UserListUser } from "@/types/user";

const USER_QUERY_KEY_BASE = "user";
const USER_QUERY_KEYS = {
  base: [USER_QUERY_KEY_BASE] as const,
};

export const useUsersQuery = (
  config: Partial<UseQueryOptions<UserListUser[]>> = {},
) => {
  return useAuthedQuery({
    queryFn: () => userService.getUsers(),
    queryKey: USER_QUERY_KEYS.base,
    ...config,
  });
};
