"use client";

import { Text } from "@mantine/core";

import {
  type BehavioralQuestion,
  type KnockoutQuestion,
  QuestionTypes,
} from "@/types/screeners";

interface KnockoutTranscriptProps {
  questions: KnockoutQuestion[];
  questionType: QuestionTypes.Knockout;
}

interface BehavioralTranscriptProps {
  questions: BehavioralQuestion[];
  questionType: QuestionTypes.Behavioral;
}

type QuestionTranscriptProps =
  | BehavioralTranscriptProps
  | KnockoutTranscriptProps;

export const QuestionTranscript = ({
  questions,
  questionType,
}: QuestionTranscriptProps) => {
  const orderedQuestions =
    questionType === QuestionTypes.Behavioral
      ? questions
      : questions.sort((a, b) => a.question_order - b.question_order);

  return (
    <div className="w-full flex flex-col border border-gray-200 rounded-lg">
      <div className="p-4">
        <Text size="lg" fw={600}>
          {questionType === QuestionTypes.Behavioral
            ? "Behavioral"
            : "Knockout"}{" "}
          Questions
        </Text>
      </div>
      <div className="flex flex-col justify-start gap-1">
        {orderedQuestions.map((question, index) => (
          <div
            key={`${question.type}_${question.sender}_${index}`}
            className={`flex p-4 flex-col ${
              question.sender === "Candidate" ? "bg-[#f8f9fa]" : ""
            }`}
          >
            <div className="w-full flex justify-between items-start">
              <Text size="sm" fw={500} tt="uppercase">
                {question.sender}
              </Text>
            </div>
            <Text size="sm">{question.message}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};
